import http from "../../Service/http.service";

const GetOrganizationList = async (
  casetype: string,
  limit: number,
  page: number,
  field: string,
  sort: string
) => {
  try {
    const response = await http.get(
      `/user/getdocument?Status=${casetype}&page=${page}&limit=${limit}&sortingOrder=${sort}&sortingField=${field}`
    );
    if (response) return response.data;
  } catch (error) {
    return error;
  }
};

const getWorkOrderListByCaseType = async (id: string, dbName: string) => {
  try {
    const response = await http.get(
      `/user/getCaseDetails?WOCaseId=${id}&dbName=${dbName}`
    );
    if (response) return response.data;
  } catch (error) {
    return error;
  }
};

const organizationService = {
  GetOrganizationList,
  getWorkOrderListByCaseType,
};
export default organizationService;
