import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showError } from "./Error-Slice";
import Store from "../Store";
import organizationService from "../Service/dash-service";

interface getDocumentProps {
  casetype: string;
  limit: number;
  page: number;
  field: string;
  sort: string;
}

export const getOrganizationList: any = createAsyncThunk(
  "/user/getdocument",
  async (
    { casetype, limit, page, field, sort }: getDocumentProps,
    thunkAPI
  ) => {
    const response = await organizationService.GetOrganizationList(
      casetype,
      limit,
      page,
      field,
      sort
    );
    if (response.success) {
      return response;
    } else {
      // dispatch(showError({ message:response?.data?.message ,status:"false"}))
      throw thunkAPI.rejectWithValue(response?.response);
    }
  }
);

export const getWorkOrderListByCaseType: any = createAsyncThunk(
  "/user/getWorkorderlist",
  async ({ id, dbName }: { id: string; dbName: string }, thunkAPI) => {
    const response = await organizationService.getWorkOrderListByCaseType(
      id,
      dbName
    );
    const dispatch = Store.dispatch;
    if (response.success) {
      return response;
    } else {
      dispatch(
        showError({
          message: response?.response?.data?.message,
          status: "false",
        })
      );
      throw thunkAPI.rejectWithValue(response?.response);
    }
  }
);

const dashSlice = createSlice({
  name: "DashSlice",
  initialState: {
    isFetching: false,
    organizationInq: [],
    isError: false,
    isTotal: 0,
    WorkOrderList: [],
    isFetchWorkList: false,
    totalPage: 0,
    OrganizationNumber: false,
  },
  reducers: {
    clearOrganizationList: (state) => {
      state.organizationInq = [];
      state.totalPage = 0;
    },
    clearWorkOrderList: (state) => {
      state.WorkOrderList = [];
    },
  },
  extraReducers: (builder) => {
    /// getAllOrganization inquirers list
    builder
      .addCase(getOrganizationList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getOrganizationList.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.OrganizationNumber = action?.payload?.isOrganizationCases;
        if (action?.payload?.documentData) {
          state.organizationInq = action.payload?.documentData;
        }
        state.totalPage = action?.payload?.totalPages;
      })
      .addCase(getOrganizationList.rejected, (state, _action) => {
        state.isError = true;
        state.isFetching = false;
        state.organizationInq = [];
      });
    builder
      .addCase(getWorkOrderListByCaseType.pending, (state) => {
        state.isFetchWorkList = true;
      })
      .addCase(getWorkOrderListByCaseType.fulfilled, (state, action) => {
        state.isFetchWorkList = false;
        state.isError = false;
        state.WorkOrderList = action?.payload?.Data;
      })
      .addCase(getWorkOrderListByCaseType.rejected, (state, action) => {
        state.isError = true;
        state.isFetchWorkList = false;
        state.WorkOrderList = [];
      });
  },
});

export const { clearOrganizationList, clearWorkOrderList } = dashSlice.actions;

export default dashSlice;
