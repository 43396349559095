import { useState } from "react";
import { Box, Button, Link, TableCell, TableRow } from "@mui/material";
import {
  handleBgColorMarker,
  handleChangeLabel,
  swedishIt,
} from "../../Utils/Utils";
import moment from "moment";

// Define the type for the item
interface Item {
  WOCaseId?: string;
  caseType?: string;
  Tenant?: string;
  Address?: {
    StreetNameAndNumber?: string;
    PostCode?: string;
    CityName?: string;
  };
  StartDate?: string;
  Name?: string;
  status?: string;
  Map_Pdf?: string;
  project_file?: string;
}

const PrivateDataTable = ({ getAllOrganization, isCaseId, handleGet }: any) => {
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>();

  const handleRowClick = (item: Item) => {
    setSelectedItemId(item.WOCaseId);
    handleGet(item);
  };

  const DataRow = ({ item }: { item: Item }) => (
    <TableRow
      className={`accordionBox__inner__title ${
        isCaseId === item.WOCaseId ? "active" : ""
      }`}
      key={item.WOCaseId}
      onClick={() => handleRowClick(item)}
      style={{
        backgroundColor:
          selectedItemId === item.WOCaseId ? "#FFFFFF" : "transparent", // White background for contrast
        boxShadow:
          selectedItemId === item.WOCaseId
            ? "0 2px 4px rgba(0, 0, 0, 0.05)"
            : "none", // Subtle shadow for depth
        borderLeft:
          selectedItemId === item.WOCaseId
            ? "5px solid #635BFF"
            : "5px solid transparent", // Vibrant border color
        transition: "all 0.3s ease-in-out", // Smooth transition for all properties
      }}
    >
      <TableCell>{item.WOCaseId}</TableCell>
      <TableCell>{item.Name}</TableCell>
      <TableCell>{item.Tenant}</TableCell>
      {/* <TableCell>{[item.Address?.StreetNameAndNumber, item.Address?.PostCode, item.Address?.CityName].filter(Boolean).join(', ')}</TableCell> */}
      <TableCell>
        {item.StartDate ? moment(item.StartDate).format("MMM DD YYYY") : ""}
      </TableCell>
      <TableCell>
        {item.caseType ? handleChangeLabel(item.caseType) : "Default Label"}
      </TableCell>
      <TableCell align="right">
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Box
            sx={{
              display: "inline-block",
              fontWeight: "bold",
              fontSize: "12px",
              py: 0.5,
              px: 1.5,
              bgcolor: handleBgColorMarker(
                item.status || "defaultStatus",
                item.caseType || "defaultCaseType"
              ),
              color: "white",
              borderRadius: "20px", // Rounded edges for badge-like appearance
              textTransform: "capitalize",
              maxWidth: 15,
              minWidth: 15,
            }}
          >
            {/* {swedishIt(item.status || "defaultStatus", item.caseType || "defaultCaseType")} */}
          </Box>
          {item.Map_Pdf && (
            <Link
              href={item.Map_Pdf}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(event) => event.stopPropagation()}
              sx={{
                textDecoration: "none",
                fontSize: "0.9rem",
                fontWeight: "bold",
              }}
            >
              Ladda ner PDF
            </Link>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {getAllOrganization.map((item: any, i: any) => (
        <DataRow item={item} key={item.WOCaseId} />
      ))}
    </>
  );
};

export default PrivateDataTable;
